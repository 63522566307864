.icon-contact {
  width: 340px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title-form,
.text-form {
  text-align: center;
}

.text-form {
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.form {
  text-align: center;
  display: block;
}

.form-span {
  display: block;
  padding: 15px;
}

.form-input {
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background-color: #000000;
  font-family: "Quicksand", sans-serif;
  width: 30%;
  color: #ffffff;
  font-size: 15px;
  opacity: 0.5;
  width: 70%;
}

.form-input:hover,
.form-input:active {
  outline-color: #f0a500;
  opacity: 1;
}

.form--textarea {
  height: 120px;
}

.button {
  display: block;
  width: 130px;
  height: 60px;
  background-color: #f0a500;
  color: #000000;
  border-radius: 30px;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.button:hover,
.button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.25);
  outline-color: #f0a500;
}

@media (min-width: 768px) {
  .icon-contact {
    width: 421px;
  }
  .form-input {
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background-color: #000000;
    font-family: "Quicksand", sans-serif;
    width: 30%;
    color: #ffffff;
    font-size: 15px;
    opacity: 0.5;
  }
}
