/* .title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blog-container-skill img {
  width: 70%;
  margin-left: 50px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.blog--container_img {
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
  position: relative;
}

.img-responsive {
  transition: transform 0.2s;
  opacity: 0.8;
}
.img-responsive:hover {
  transform: scale(1.2);
  opacity: 1;
}

.container--math {
  display: block;
}

.logo-git {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .blog-container-skill {
    display: flex;
  }

  .blog--container_img {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  .blog-container-skill img {
    width: 25%;
    margin-left: 70px;
    margin-bottom: 60px;
    margin-right: 50px;
  }
} */

.icon-warning{
  font-size: 60px;
}

.title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}
.containe--skill {
  flex-wrap: wrap;
}

.warning-tape{
  width: 80%;
}

.contruction-div{
  text-align: center;
  font-size: 24px;
}

.blog-container-skill {
  display: block;
  text-align: center;
}

.blog--container_img-skills {
  text-align: center;
  transform-origin: center left;
}

.blog--container_text {
  text-align: justify;
  text-justify: inter-word;
  padding-left: 10px;
  padding-right: 10px;
}

.blog--container_img-skills img {
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.img-responsive__skills {
  opacity: 0.8;
}

.img-responsive__skills:hover {
  opacity: 1;
}

@media (min-width: 768px) {
  .blog-container-skill {
    display: flex;
    flex-wrap: wrap;
  }

  .blog--container_img-skills {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 50px;
    width: 30%;
  }

  .blog--container_img-skills img {
    width: 80%;
  }
}
