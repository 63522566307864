.footer {
  background-color: #000000;
  width: 70%;
  height: 100px;
  margin-top: 10%;
  padding: 20px;
  border-top: 1px solid #ffffff;
  margin-left: 15%;
}

.footer ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

svg {
  font-size: 28px;
  fill: #ffffff;
  margin-left: 10px;
}

svg:hover,
svg:focus,
svg:visited {
  fill: #f0a500;
}
