.nav {
  font-family: "Quicksand", sans-serif;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 30px;
}

.nav__img {
  width: 150px;
}

.nav ul li a {
  text-decoration: none;
  color: #ffffff;
}

.nav ul {
  list-style: none;
}

.nav-left ul li a {
  font-size: 25px;
  font-weight: bold;
}

.nav-left ul li a:hover {
  color: #f0a500;
}

.nav-right__li {
  padding-bottom: 10px;
  font-size: 18px;
}

.nav-right__li:hover,
.nav-right__li:active {
  text-decoration: underline;
}

.nav-options {
  display: none;
}
.nav-options.active {
  display: flex;
  position: absolute;
  background-color: #082032;
  width: 100%;
  height: 130px;
  font-weight: bold;
  top: 80px;
  right: 0;
  transition: all 0.5s ease;
  z-index: 1;
  flex-direction: column;
  list-style-type: none;
  align-content: center;
  text-align: center;
}

@media (min-width: 768px) {
  .nav-right ul {
    display: flex;
    justify-content: flex-end;
  }
  .nav-right ul li {
    padding-right: 20px;
  }
  .nav-right ul li a {
    font-size: 18px;
  }

  .nav-right ul li a:hover {
    text-decoration: underline;
    color: #f0a500;
  }

  .mobile-menu {
    display: none;
  }
}
