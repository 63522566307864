.title-blog {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blog-container img {
  width: 70%;
  margin-left: 50px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.blog--container__img {
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
  position: relative;
}

.img-responsive {
  transition: transform 0.2s;
  opacity: 0.8;
}
.img-responsive:hover {
  transform: scale(1.2);
  opacity: 1;
}

.btn-read {
  width: 130px;
  height: 60px;
  padding: 10px;
  background-color: #f0a500;
  border: none;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
}

.btn-read a {
  text-decoration: none;
  color: #000000;
}

.btn-read:hover,
.btn-read:focus,
.btn-read:visited {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.25);
  outline-color: #f0a500;
}

@media (min-width: 768px) {
  .blog-container {
    display: flex;
  }

  .blog--container__img {
    justify-content: space-between;
  }
  .blog-container img {
    width: 25%;
    margin-left: 70px;
    margin-bottom: 60px;
  }
}
