.btn-cv {
  margin-top: 20px;
  width: 130px;
  height: 60px;
  padding: 10px;
  background-color: #f0a500;
  border: none;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
}

.btn-cv a {
  text-decoration: none;
  color: #000000;
}

.btn-cv:hover,
.btn-cv:focus {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.25);
  outline-color: #f0a500;
}
