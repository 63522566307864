.container {
  text-align: center;
  display: block;
  align-items: center;
  margin-top: 60px;
}

.container-title {
  font-size: 28px;
  padding-bottom: 30px;
  font-weight: bold;
}

.container-title__2 {
  font-size: 28px;
  padding-bottom: 30px;
  font-weight: bold;
  margin-top: 30px;
}

.container--text {
  font-size: 18px;
  padding-left: 50px;
  padding-right: 60px;
  text-align: justify;
  text-justify: inter-word;
}

.about__img {
  width: 340px;
}

.front-end {
  margin-top: 20px;
  margin-bottom: 20px;
}
.container--fontend {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 60px;
  text-align: justify;
  font-size: 18px;
}

@media (min-width: 768px) {
  .about__img {
    width: 421px;
    margin-left: 100px;
  }
  .container {
    display: flex;
  }
  .container--text {
    padding-left: 100px;
    padding-right: 150px;
  }
  .container--skills__sec {
    display: flex;
    justify-content: space-around;
    margin-right: 150px;
    margin-left: 100px;
  }
  .container--fontend {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 40px;
  }
}
