@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  text-align: center;
  display: block;
  align-items: center;
  margin-top: 60px;
}

.container-title {
  font-size: 28px;
  padding-bottom: 30px;
  font-weight: bold;
}

.container-title__2 {
  font-size: 28px;
  padding-bottom: 30px;
  font-weight: bold;
  margin-top: 30px;
}

.container--text {
  font-size: 18px;
  padding-left: 50px;
  padding-right: 60px;
  text-align: justify;
  text-justify: inter-word;
}

.about__img {
  width: 340px;
}

.front-end {
  margin-top: 20px;
  margin-bottom: 20px;
}
.container--fontend {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 60px;
  text-align: justify;
  font-size: 18px;
}

@media (min-width: 768px) {
  .about__img {
    width: 421px;
    margin-left: 100px;
  }
  .container {
    display: flex;
  }
  .container--text {
    padding-left: 100px;
    padding-right: 150px;
  }
  .container--skills__sec {
    display: flex;
    justify-content: space-around;
    margin-right: 150px;
    margin-left: 100px;
  }
  .container--fontend {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 40px;
  }
}

.nav {
  font-family: "Quicksand", sans-serif;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 30px;
}

.nav__img {
  width: 150px;
}

.nav ul li a {
  text-decoration: none;
  color: #ffffff;
}

.nav ul {
  list-style: none;
}

.nav-left ul li a {
  font-size: 25px;
  font-weight: bold;
}

.nav-left ul li a:hover {
  color: #f0a500;
}

.nav-right__li {
  padding-bottom: 10px;
  font-size: 18px;
}

.nav-right__li:hover,
.nav-right__li:active {
  text-decoration: underline;
}

.nav-options {
  display: none;
}
.nav-options.active {
  display: flex;
  position: absolute;
  background-color: #082032;
  width: 100%;
  height: 130px;
  font-weight: bold;
  top: 80px;
  right: 0;
  transition: all 0.5s ease;
  z-index: 1;
  flex-direction: column;
  list-style-type: none;
  align-content: center;
  text-align: center;
}

@media (min-width: 768px) {
  .nav-right ul {
    display: flex;
    justify-content: flex-end;
  }
  .nav-right ul li {
    padding-right: 20px;
  }
  .nav-right ul li a {
    font-size: 18px;
  }

  .nav-right ul li a:hover {
    text-decoration: underline;
    color: #f0a500;
  }

  .mobile-menu {
    display: none;
  }
}

.footer {
  background-color: #000000;
  width: 70%;
  height: 100px;
  margin-top: 10%;
  padding: 20px;
  border-top: 1px solid #ffffff;
  margin-left: 15%;
}

.footer ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

svg {
  font-size: 28px;
  fill: #ffffff;
  margin-left: 10px;
}

svg:hover,
svg:focus,
svg:visited {
  fill: #f0a500;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #000000;
  color: #ffffff;
  font-family: "Quicksand", sans-serif;
}

.hero-title {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 15px;
}

.hero__img {
  width: 340px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hero__text {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .hero__img {
    width: 421px;
  }
}

.btn-cv {
  margin-top: 20px;
  width: 130px;
  height: 60px;
  padding: 10px;
  background-color: #f0a500;
  border: none;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
}

.btn-cv a {
  text-decoration: none;
  color: #000000;
}

.btn-cv:hover,
.btn-cv:focus {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.25);
  outline-color: #f0a500;
}

/* .title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blog-container-skill img {
  width: 70%;
  margin-left: 50px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.blog--container_img {
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
  position: relative;
}

.img-responsive {
  transition: transform 0.2s;
  opacity: 0.8;
}
.img-responsive:hover {
  transform: scale(1.2);
  opacity: 1;
}

.container--math {
  display: block;
}

.logo-git {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .blog-container-skill {
    display: flex;
  }

  .blog--container_img {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  .blog-container-skill img {
    width: 25%;
    margin-left: 70px;
    margin-bottom: 60px;
    margin-right: 50px;
  }
} */

.icon-warning{
  font-size: 60px;
}

.title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}
.containe--skill {
  flex-wrap: wrap;
}

.warning-tape{
  width: 80%;
}

.contruction-div{
  text-align: center;
  font-size: 24px;
}

.blog-container-skill {
  display: block;
  text-align: center;
}

.blog--container_img-skills {
  text-align: center;
  -webkit-transform-origin: center left;
          transform-origin: center left;
}

.blog--container_text {
  text-align: justify;
  text-justify: inter-word;
  padding-left: 10px;
  padding-right: 10px;
}

.blog--container_img-skills img {
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.img-responsive__skills {
  opacity: 0.8;
}

.img-responsive__skills:hover {
  opacity: 1;
}

@media (min-width: 768px) {
  .blog-container-skill {
    display: flex;
    flex-wrap: wrap;
  }

  .blog--container_img-skills {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 50px;
    width: 30%;
  }

  .blog--container_img-skills img {
    width: 80%;
  }
}

.title-blog {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blog-container img {
  width: 70%;
  margin-left: 50px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.blog--container__img {
  cursor: pointer;
  transition: 450ms all;
  -webkit-transform-origin: center left;
          transform-origin: center left;
  position: relative;
}

.img-responsive {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  opacity: 0.8;
}
.img-responsive:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 1;
}

.btn-read {
  width: 130px;
  height: 60px;
  padding: 10px;
  background-color: #f0a500;
  border: none;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
}

.btn-read a {
  text-decoration: none;
  color: #000000;
}

.btn-read:hover,
.btn-read:focus,
.btn-read:visited {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.25);
  outline-color: #f0a500;
}

@media (min-width: 768px) {
  .blog-container {
    display: flex;
  }

  .blog--container__img {
    justify-content: space-between;
  }
  .blog-container img {
    width: 25%;
    margin-left: 70px;
    margin-bottom: 60px;
  }
}

.icon-contact {
  width: 340px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title-form,
.text-form {
  text-align: center;
}

.text-form {
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.form {
  text-align: center;
  display: block;
}

.form-span {
  display: block;
  padding: 15px;
}

.form-input {
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background-color: #000000;
  font-family: "Quicksand", sans-serif;
  width: 30%;
  color: #ffffff;
  font-size: 15px;
  opacity: 0.5;
  width: 70%;
}

.form-input:hover,
.form-input:active {
  outline-color: #f0a500;
  opacity: 1;
}

.form--textarea {
  height: 120px;
}

.button {
  display: block;
  width: 130px;
  height: 60px;
  background-color: #f0a500;
  color: #000000;
  border-radius: 30px;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.button:hover,
.button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.25);
  outline-color: #f0a500;
}

@media (min-width: 768px) {
  .icon-contact {
    width: 421px;
  }
  .form-input {
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background-color: #000000;
    font-family: "Quicksand", sans-serif;
    width: 30%;
    color: #ffffff;
    font-size: 15px;
    opacity: 0.5;
  }
}

