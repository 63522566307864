@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #000000;
  color: #ffffff;
  font-family: "Quicksand", sans-serif;
}
